import React from "react";
import { useLocation } from "react-router-dom";
import { Logo } from "../components/Logo/logo";

const Success = () => {
  const { state: imgSrc } = useLocation();

  return (
    <div className="flex flex-col md:w-2/5 items-center w-full">
      <Logo containerStyle="absolute" imgSrc={imgSrc} />

      <div className="w-full grow flex justify-center">
        <div className=" w-full md:w-4/5 lg:px-8 self-center">
          <h3 className="p-1 text-center text-6xl font-bold  font-PTSerif primary line79_5">
            Success!
          </h3>
          <p className="mx-4 my-4 text-center text-base font-normal font-Rubik black primary line18_96">
            Thank you for your consent. We are now able to store your data and
            provide you with a better experience. We will contact you again as
            soon as the consent nears its expiry date.
          </p>
          <a className="p-2  my-6 text-center text-base font-Rubik font-bold linkColor flex justify-center line18_96">
            You can safely close this window.
          </a>
        </div>
      </div>
    </div>
  );
};

export default Success;
