import React from "react";
import { Loading } from "../Loading";

export const Button = ({ outline, handleClick, disabled, loading, name }) => {
  if (outline) {
    return (
      <button
        onClick={() => (!disabled ? handleClick() : null)}
        className={`bg-transparent muteColor flex justify-center ${
          disabled ? "btn-disable" : "btn_outline_primary"
        } border-2 font-semibold py-3 px-4 mr-2 min-w-30`}
      >
        {loading ? <Loading height={25} width={25} color={"#4951ff"} /> : name}
      </button>
    );
  }
  return (
    <button
      onClick={() => (!disabled ? handleClick() : null)}
      className="btn_primary text-white font-bold py-3 px-4 ml-2 border-2 min-w-30 flex justify-center"
    >
      {loading ? <Loading height={25} width={25} /> : name}
    </button>
  );
};
