import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from '../components/Buttons/button';
import { Loading } from '../components/Loading';
import { Logo } from '../components/Logo/logo';
import { Selector } from '../components/Option/selectable_option';
import Api from '../services/api';
import { setToken } from '../services/local_storage';

const Home = () => {
  const navigate = useNavigate();

  const [loadingConsent, setLoadingConsent] = useState(false);
  const [loadingAgreeToAll, setLoadingAgreeToAll] = useState(false);
  const [loadingSavePrefs, setLoadingSavePrefs] = useState(false);
  const [isDisableSavePrefs, setIsDisableSavePrefs] = useState(true);

  const [consents, setConsents] = useState({});
  const [searchParams] = useSearchParams();

  const loadConsent = async () => {
    setLoadingConsent(true);
    try {
      const {
        data: [res],
      } = await Api.get(
        '/odata/my/consents?$select=*&$expand=Talent,Tenant,ConsentItems($select=*;$expand=Translations)'
      );
      setLoadingConsent(false);
      setConsents(res);
    } catch (err) {
      // console.log("error ", err);
      setLoadingConsent(false);
      navigate('/error', {
        state: {
          status: err?.response?.status,
          errorMessage: err?.response?.statusText,
        },
      });
    }
  };

  useEffect(() => {
    if (searchParams.get('token')) {
      setToken(searchParams.get('token'));
    }
    // console.log("token is ", (getToken() || searchParams.get("token")) ?? "-");

    loadConsent();
  }, []);

  useEffect(() => {
    // if there is any which is mandatory BUT has not yet been consented ..
    const notAllMandatoryConsentedYet = consents?.consentItems?.some(
      (e) => e.isMandatory && !e.consented
    );

    setIsDisableSavePrefs(notAllMandatoryConsentedYet); // disable save Preferences when not all mandatory items have been Consented Yet
  }, [consents]);

  const handleSelection = ({ option }) => {
    setConsents((prevState) => {
      const newState = { ...prevState };
      const updatedOption = newState?.consentItems?.map((consentItem) =>
        consentItem?.id === option.id
          ? { ...consentItem, consented: !option.consented }
          : consentItem
      );
      newState.consentItems = updatedOption;
      return newState;
    });
  };

  const handleAgreeToall = async () => {
    const newState = { ...consents };
    const updatedOption = newState?.consentItems?.map((consentItem) => ({
      ...consentItem,
      consented: true,
    }));
    newState.consentItems = updatedOption;
    setConsents(newState);

    setLoadingAgreeToAll(true);
    try {
      const res = await Api.post('/api/my/consents', {
        consentItems: updatedOption?.map((item) => ({ id: item.id })),
      });
      console.log('response ', res);
      setLoadingAgreeToAll(false);
      navigate('/success', { state: consents?.tenant?.image });
    } catch (err) {
      // console.log("error ", er);
      setLoadingAgreeToAll(false);
      navigate('/error', {
        state: {
          status: err?.response?.status,
          errorMessage: err?.response?.statusText,
        },
      });
    }
  };

  const handleSavePrefs = async () => {
    const selectedOptions = consents.consentItems
      .filter((cItem) => cItem.consented === true)
      .map((item) => ({
        id: item.id,
      }));

    setLoadingSavePrefs(true);
    try {
      const res = await Api.post('/api/my/consents', {
        consentItems: selectedOptions,
      });
      setLoadingSavePrefs(false);
      navigate('/success', { state: consents?.tenant?.image });
    } catch (err) {
      // console.log("error ", er);
      setLoadingSavePrefs(false);
      navigate('/error', {
        state: {
          status: err?.response?.status,
          errorMessage: err?.response?.statusText,
        },
      });
    }
  };

  return (
    <div
      className={`flex flex-col sm:w-4/5 md:4/6 xl:w-4/6 items-center w-full ${
        loadingConsent ? 'justify-center' : ''
      }`}
    >
      {loadingConsent ? (
        <Loading height={60} width={60} color={'#2a1451'} />
      ) : (
        <>
          <Logo imgSrc={consents?.tenant?.image} />

          <div className="w-full grow flex justify-center">
            <div className="flex flex-col">
              <h3 className="p-1 text-center text-2xl font-Rubik primary line28_44">
                {` Hi ${consents?.talent?.firstName ?? '-'} ${
                  consents?.talent?.lastName ?? '-'
                }`}
              </h3>
              <h3 className="p-1 text-center text-6xl  font-bold font-PTSerif primary line79_5">
                Let's get started
              </h3>
              <div className="w-full md:w-4/5 px-8 lg:px-20 mx-auto ">
                <p className="p-4 text-base text-center font-normal font-Rubik primary line18_96">
                  Thank you for your interest in working with{' '}
                  {consents?.tenant?.name}. Before we can proceed we require
                  your consent to store your data. Please tick the boxes of your
                  choice to ensure a smooth experience.
                </p>
                <p className="p-4 text-base text-center font-normal font-Rubik primary line18_96">
                  Your data is solely being processed in order to perform our
                  professional activities.
                </p>
              </div>
              <div className="w-full md:w-4/5 px-6 mx-auto h-full">
                <Selector
                  disabled={loadingAgreeToAll || loadingSavePrefs}
                  onSelect={handleSelection}
                  options={consents?.consentItems}
                />
                <div className="flex lg:justify-end justify-center mx-6 my-8">
                  <Button
                    outline
                    disabled={isDisableSavePrefs}
                    loading={loadingSavePrefs}
                    handleClick={() => handleSavePrefs()}
                    name="Save preferences"
                  />
                  <Button
                    loading={loadingAgreeToAll}
                    disabled={loadingAgreeToAll === true}
                    handleClick={() => handleAgreeToall()}
                    name="Agree to all"
                  />
                </div>
                <div className="flex justify-end md:mx-6 my-12 mx-0 text-end">
                  <a
                    href=""
                    className="underline font-Rubik neutralColor m-3 text-base font-normal line16_59"
                    onClick={() =>
                      navigate('/decline', {
                        state: consents?.tenant?.image,
                      })
                    }
                  >
                    No thanks, I'm not interested in finding a great job
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
