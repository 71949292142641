import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Error = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { status, errorMessage } = state ?? {};
  console.log('status ', { status });

  return (
    <div className="flex flex-col md:w-2/5 items-center w-full">
      <div className="w-full grow flex justify-center">
        <div className=" w-full md:w-4/5 lg:px-8 self-center">
          <h3 className="p-1 text-center text-6xl font-bold  font-PTSerif primary line79_5">
            {`${status ?? 'Something went wrong'}`}
          </h3>
          <p className="mx-4 my-4 text-center text-base font-normal font-Rubik black primary line18_96">
            {/* Page not found */}
          </p>
          <a
            href="#"
            onClick={() => navigate('/')}
            className="p-2 my-6 text-center text-base font-Rubik font-bold linkColor flex justify-center line18_96"
          >
            {`${errorMessage ? 'Try again' : 'Go to home'}`}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Error;
