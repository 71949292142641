import axios from "axios";
import * as store from "./local_storage";

// timeout
// default base url

axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT;

axios.interceptors.request.use(
  async (config) => {
    config.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getToken()}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// interceptor response to manage the response
axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    console.log("error is ", error);
    window.location = "/error";
  }

  return Promise.reject(error);
});

export default {
  get: axios.get,
  post: axios.post,
};
