import React from 'react';

import SharePassLogo from '../../assets/sharepass_logo.png';

export const Logo = ({ width = 150, containerStyle = '', imgSrc }) => {
  return (
    <div className={`w-full grow-0 ${containerStyle}`}>
      <img
        className="my-16 mx-auto"
        width={width}
        src={` ${imgSrc ? 'data:image/png;base64, ' + imgSrc : SharePassLogo}`}
      />
    </div>
  );
};
