import {
  ArrowNarrowRightIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/solid';
import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';

export function OptionItem({ option, disabled, onSelect }) {
  const [descriptionHtml, setDescriptionHtml] = useState('<p>-</p>');
  const [showDescription, setShowDescription] = useState(false);

  useEffect(() => {
    const sanitizedHtml = DOMPurify.sanitize(getOptionDescription({ option }))
      ?.replace(/Proxima-Nova-Regular/g, 'Rubik')
      ?.replace(/font-size: medium;/g, '');
    console.log('>>>><>', sanitizedHtml);
    setDescriptionHtml(sanitizedHtml);
  }, [option]);

  const getOptionDescription = ({ option }) => {
    const englishTranslation = option?.translations.find(
      (d) => d?.language === 'EN'
    );
    return englishTranslation?.description
      ? englishTranslation?.description
      : '-';
  };

  const getOptionSummary = ({ option }) => {
    const englishTranslation = option?.translations.find(
      (d) => d?.language === 'EN'
    );
    return englishTranslation?.summary ?? '<p>-</p>';
  };

  return (
    <div
      className={` ${showDescription ? 'consents_option m-4 mx-6 p-3' : ''} `}
    >
      <div
        className={`flex  items-start justify-start  ${
          showDescription ? '' : 'm-4 mx-6 p-3 bg-white consents_option'
        } `}
      >
        <div
          className="m-2 mr-3"
          onClick={() => (!disabled ? onSelect({ option }) : null)}
        >
          <p
            className={`w-7 h-7 rounded-full text-center ${
              option?.consented
                ? 'bg-green-600 border-2'
                : 'muteBackground border-2 border-grey-300'
            } `}
          >
            {option?.consented && <CheckIcon className="text-white" />}
          </p>
        </div>
        <div
          className="m-1 mx-3 grow"
          onClick={() => (!disabled ? onSelect({ option }) : null)}
        >
          <p className="font-medium text-base flex primary font-Rubik line18_96">
            {option?.name ?? '-'}
            <ArrowNarrowRightIcon className="h-4 mx-1 self-center" />
          </p>
          <p className="secondary text-sm font-normal font-Rubik line16_59">
            {getOptionSummary({ option })}
          </p>
        </div>
        <div
          className="m-2 bg-red"
          onClick={() => setShowDescription(!showDescription)}
        >
          {showDescription ? (
            <ChevronUpIcon className="h-5 self-center icon_primary" />
          ) : (
            <ChevronDownIcon className="h-5 self-center icon_primary" />
          )}
        </div>
      </div>
      {showDescription && (
        <>
          <div className="py-2 -mr-3 -ml-3">
            <div className="w-full border-t border-gray-300"></div>
          </div>

          <div className="h-40 overflow-y-scroll mx-2">
            <div
              className="text-sm font-Rubik font-normal secondary line16_59"
              dangerouslySetInnerHTML={{
                __html: descriptionHtml,
              }}
            ></div>
          </div>
        </>
      )}
    </div>
  );
}
