import React from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Home from "./pages";
import Success from "./pages/success";
import Decline from "./pages/decline";
import Error from "./pages/error";
import bgImage from "./assets/background.svg";

function App() {
  return (
    <BrowserRouter>
      <div
        className="bg-white min-h-screen flex justify-center"
        style={{
          backgroundImage: `url(${bgImage}) `,
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/consent" element={<Home />} />
          <Route path="/success" element={<Success />} />
          <Route path="/decline" element={<Decline />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
