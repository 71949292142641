import React from 'react';
import { OptionItem } from './optionItem';

export const Selector = ({ onSelect, options, disabled }) => {
  return (
    <>
      {options && options?.length ? (
        options.map((option, index) => {
          return (
            <OptionItem
              option={option}
              disabled={disabled}
              key={index}
              onSelect={onSelect}
            />
          );
        })
      ) : (
        <div className="bg-white m-3 my-10 p-2 consents_option">
          <p className="m-6 font-medium text-xl  primary text-center">
            No options available
          </p>
        </div>
      )}
    </>
  );
};
