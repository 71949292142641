import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowNarrowRightIcon } from '@heroicons/react/solid';
import { Logo } from '../components/Logo/logo';

const Decline = () => {
  const navigate = useNavigate();
  const { state: imgSrc } = useLocation();
  return (
    <div className="flex flex-col md:w-2/5 items-center w-full">
      <Logo containerStyle="absolute" imgSrc={imgSrc} />

      <div className="w-full grow flex justify-center">
        <div className=" w-full md:w-4/5 lg:px-8 self-center">
          <h3 className="p-1 text-center text-6xl font-bold  font-PTSerif primary line79_5">
            Are you sure?
          </h3>
          <p className="mx-4 my-4 text-center text-base font-normal font-Rubik black primary line18_96">
            Without your consent to store your data we can’t perform our
            professional services, and find a job that matches with what you’re
            looking for. If you’re ok with this, you can close this window.
            Otherwise, please change your preferences.
          </p>
          <a
            href=""
            onClick={() => navigate('/')}
            className="p-2 my-6 text-center text-base font-Rubik font-bold linkColor flex justify-center line18_96"
          >
            Change my preferences
            <ArrowNarrowRightIcon className="h-6 mx-4 font-Rubik font-bold linkColor " />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Decline;
